.Control-Panel {
  display: inline-block;
  margin-top: 60px;
  /* height: 400px; */
  /* width: 100%; */
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
}

.control-panel-link {
  display: block;
  text-decoration: none;
  color: black;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  margin: 10px;
}

.control-panel-link:hover {
  background-color: lightgrey;
}

.control-panel-link:visited {
  color: black;
}

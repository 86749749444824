.who-we-are {
  background-color: white;
  padding: 0 0 20px 0;
}

.about-title {
  margin-bottom: 20px;
  padding: 10px;
  background-color: lime;
}

.about-text {
  font-size: 16px;
  max-width: 72%;
  margin-bottom: 20px;
}

.prices-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #00d30d;
  /* background-color: #062444; */
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 12px 14px;
  border-radius: 10px;
  transition: 0.75s background-color, 0.75s color, 0.75s border-color;
  margin: 10px auto 10px auto;
}

.prices-button:hover {
  background-color: #00d30d;
  color: white;
}

/* .mini-cart-container {
  display: absolute;
} */

.mini-cart {
  position: fixed;
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  z-index: 1000000000;
  border-bottom: 3px solid #A9B2BC;
  margin-top: 36px;
}

.miniCart-display-text {
  display: inline-block;
  font-size: 14px;
  margin: 4px 4px;
}

.miniCart-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #00d30d;
  /* background-color: #062444; */
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 6px 8px;
  border-radius: 10px;
  -webkit-transition: .6s background-color, .6s color, .6s border-color;
  transition: .6s background-color, .6s color, .6s border-color;
  margin: 6px 0;
  font-size: 12px;
}

.miniCart-button:hover {
  background-color:  #00d30d;
  color: #f3f3f3;
  border-color: transparent;
}
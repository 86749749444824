.Elite-Products {
  background-color: white;
  min-height: 100vh;
  padding-bottom: 40px;
}

.elite-product {
  background-image: url("../../images/Nylon-American-Flag-closeup-1.jpg");
  background-size: 210%;
  background-position: -160px 0px;
  background-repeat: no-repeat;
  padding: 10px;
  width: 82%;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
}

.elite-p {
  background-color: white;
  border: 1px solid black;
  padding: 4px;
  border-radius: 10px;
  min-width: 280px;
}

.elite-product-chocolate {
  margin: 0 auto;
  background: white;
  background-image: url("../../images/VibeChocolate.png");
  background-size: 160px;
  background-position: 60px 10px;
  width: 280px;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 10px;
}

.elite-product-caramel {
  margin: 0 auto;
  background: white;
  background-image: url("../../images/VibeCarmel.png");
  background-size: 160px;
  background-position: 60px 10px;
  width: 280px;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 10px;
}

.elite-product-rice-krispie {
  margin: 0 auto;
  background: white;
  background-image: url("../../images/VibeRiceKrispieTreat.png");
  background-size: 160px;
  background-position: 60px 25px;
  width: 280px;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 10px;
}

.elite-product-capsules {
  margin: 0 auto;
  background: white;
  background-image: url("../../images/VibeCapsels.png");
  background-size: 80px;
  background-position: 100px 25px;
  width: 280px;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 10px;
}

.elite-product-cart {
  margin: 0 auto;
  background: white;
  background-image: url("../../images/VibeCart.png");
  background-size: 60px;
  background-position: 100px 12px;
  width: 280px;
  height: 200px;
  background-repeat: no-repeat;
  border: 1px solid black;
  border-radius: 10px;
}

.prices-intro {
  font-size: 16px;
}

.prices-note {
  font-size: 15px;
}

.prices-logo {
  background-image: url("../../images/EliteExtractionsBlackGreenSpace.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

.prices-note {
  font-size: 15px;
}

@media only screen and (min-width: 500px) {
  .elite-products-container {
    display: flex;
  }

  .elite-product {
    background-image: url("../../images/Nylon-American-Flag-closeup-1.jpg");
    background-size: 210%;
    background-position: -160px 0px;
    background-repeat: no-repeat;
    padding: 10px;
    width: 300px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid black;
    margin-bottom: 20px;
  }
}

.past-order-details {
 margin-top: 60px;
}

.past-order-dispensary-name {
  color: #f3f3f3;
 text-align: center;

}

.past-order-container {
  background-color: #f3f3f3;
}

.pod-reorder-button {
  display: inline-block;
  padding: 20px;
  margin-top: 30px;
  border: 2px solid #c5c5c5;
  background-color: #f3f3f3;
  text-decoration: none;
  border-radius: 10px;
  color: black;
}

pod-reorder-button:visited {
  color: black;
}

.pod-reorder-button:hover {
  background-color: lime;
  border-color: transparent;
}
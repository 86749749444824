.thank-you-page {
  background-color: #f3f3f3;
  display: inline-block;
  max-width: 80%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
}

.thank-you-logo {
  background-image: url('../../images/EliteExtractionsWhiteGreen.png');
  width: 340px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  /* border: 2px solid magenta; */
  margin: 60px auto 10px auto;
  /* border: 2px solid magenta; */
}
.Prices {
  background-color: white;
  max-width: 90%;
  margin: 0 auto 40px auto;
  padding: 10px;
}

.prices-logo {
  background-image: url("../../images/EliteExtractionsBlackGreenSpace.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

.prices-intro {
  font-size: 16px;
}

.prices-note {
  font-size: 15px;
}

th {
  background-color: lime;
  padding: 6px;
  border: 1px solid gray;
}

.last-table {
  margin-top: 20px;
}

.testey:hover {
  cursor: pointer;
  color: grey;
}

.testey:visited {
  color: white;
}

.testey:visited:hover {
  color: grey;
}

.product {
  border: 1px solid slategray; width: 90vw;
  /* border-radius: 10px; */
  display: inline-block;
  margin: 10px auto;
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
  background-color: #f3f3f3;
  border-radius: 10px;
  /* border:  0 solid #082443; */
}

.product-title {
  font-weight: 500;
  /* margin-top: 10px; */
}

.product-price {
  font-weight: 500;
  /* color: #05ee05; */
}

.product-image {
  height: 60vmin;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;


}

.product-image-1 {
  background-image: url('../../images/High-Terapine-Cartridge.png');
}

.product-image-2 {
  background-image: url('../../images/Diamonds.png');
}

.product-image-3 {
  background-image: url('../../images/Live-Resin-Vaporizers.png');
}

.product-image-4 {
  background-image: url('../../images/Sugar.png');
}

.product-favorite-icon {
  height: 24px;
  width: 24px;
  background-image: url('../../icons/favorite-blank-white.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  margin: 20px 0 0 20px;
  z-index: 1000;
  background-color: #000000;
  -webkit-box-shadow: 0px 0px 18px 10px rgba(20,4,20,1);
  -moz-box-shadow: 0px 0px 18px 10px rgba(20,4,20,1);
  box-shadow: 0px 0px 18px 10px rgba(20,4,20,1);
  border-radius: 10% 10% 100% 100%;
  transition: background-image .5s ease-in-out;
  cursor: pointer;
}

.product-favorite-icon:hover {
  background-image: url('../../icons/favorite-red.png')
}

.product-favorited {
  background-image: url('../../icons/favorited-red.png')
}

.product-favorited:hover {
  background-image: url('../../icons/unfavorite-white.png')
}

.product-button {
  height: 40px;
  width: 90%;
  margin: 10px auto;
  border-radius: 10px;
  /* background-color: lightgray; */
  /* padding: 10px auto; */
  border: 1px solid slategray;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  -webkit-transition: background-color .6s ease-in-out, color .6s ease-in-out, .6s border-color ease-in-out;
  transition: background-color .6s ease-in-out, color .6s ease-in-out, .6s border-color ease-in-out;
}

.product-button:hover {
  background-color: #00d30d;
  color: #f3f3f3;
  border-color: transparent;
}

/* .details-button:hover {
  background-color: #058ED9;
  color: #f3f3f3fa;;
} */

.product-button-text {
  /* margin: 10px auto; */
  /* justify-content: center; */
  font-size: 14px;
  font-weight: 500;
}

.add-to-cart-container {
  /* padding-top: 10px; */
  /* border: 1px solid slategray; */
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}


.quantity-input {
  border: 1px solid slategray;
  display: inline-block;
  width: 20%;
  height: 40px;
  /* justify-self: baseline; */
  margin: 0;
  padding: 0 0 0 24px;
}

.add-to-cart-button {
  /* margin-top: 10px; */
  display: inline-flex;
  width: 60%;
  /* margin-bottom: 10px; */
  margin: 0 0 10px 0;
  border-radius: 0 10px 10px 0;
}

.product-button:active {
  background-color: #0d6899;
}

.amount-container {
  border: 1px solid slategray;
  width: 40%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px 0 0 10px;
}

.quantity-display {
  /* border-left: 1px solid slategray;
  border-right: 1px solid slategray;
  height: 100%; */
  background-color: lightcyan;
  width: 33%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
}

.quantity-display-reduce,
.quantity-display-increase {
  background-color: lightgreen;
  width: 33.4%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  -webkit-transition: background-color .3s ease-in-out, color .3s ease-in-out;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.quantity-display-increase:hover {
  background-color: #00ff00;
  cursor: pointer;
}

.quantity-display-increase:active {
  background-color: #08be42d5;
  cursor: pointer;
}

.quantity-display-reduce {
  background-color: lightcoral;
  border-radius: 10px 0 0 10px;
}

.quantity-display-reduce:hover {
  background-color: #f72727;
  cursor: pointer;
}

.quantity-display-reduce:active {
  background-color: #d11111;
  cursor: pointer;
}

/* FAV ANIMATION */



/* ------------------------------------- */

/* Button Animation */
@-webkit-keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.15, 0.75, 1);
            transform: scale3d(1.15, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}


/*  ------------------------------------- */



@media only screen and (min-width: 600px) {
  .product {
    width: 40vw;
    /* background-color: lightblue; */
  }

  .product-image {
    height: 28vmin;
  }

  /* .product-favorite-icon {
    margin: 3vmin 0 0 33vmin;
  } */
}

@media only screen and (min-width: 700px) {
   .product-image {
    height: 33vmin;
  }

    /* .product-favorite-icon {
    margin: 3vmin 0 0 35vmin;
  } */
}

@media only screen and (min-width: 800px) {
   .product-image {
    height: 37vmin;
  }

      /* .product-favorite-icon {
    margin: 3vmin 0 0 35vmin;
  } */
}

@media only screen and (min-width: 895px) {
  .product {
    width: 33vw;
  }
}

@media only screen and (min-width: 1000px) {
  .product {
    width: 20vw;
  }

  .product-image {
    /* height: 26vmin; */
    background-size: cover;
    background-position: center;
  }
}



.login-container {
  min-height: 100vh;
  background-color: #1e1f20;
  padding: 20px 0 80px 0;
}

.login-logo {
  background-image: url('../../images/EliteExtractionsWhiteGreen.png');
  width: 70vmin;
  height: 40vmin;
  background-size: contain;
  background-repeat: no-repeat;
  /* border: 2px solid magenta; */
  margin: 30px auto 20px auto;
}

.login {
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  border: 2px solid #082443;
  max-width: 84%;
  border-radius: 10px;
  background-color: #f3f3f3;
}

.login-title {
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 24px;
}

.login-signup-text {
  margin: 0 auto;
  text-align: center;
  font-weight: 200;
}

.login-page-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #00d30d;
  /* background-color: #062444; */
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 12px 14px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
  margin: 10px auto 10px auto;
}

.login-page-button:hover {
  background-color: #00d30d;
  color: #f3f3f3fa;
  border-color: transparent;
}

.login-page-button:disabled {
  pointer-events: none;
  color: #A9B2BC;
  border-color: #A9B2BC;
  background-color: #f3f3f3;
}

.submit-btn {
  margin-top: 0;
}

.login-input {
  padding: 10px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 10px;
  border: 2px solid #A9B2BC;
  width: 280px;
  background-color: #f3f3f3fa;
}

.signup-label {
  margin-left: 8px;
  font-size: 14px;
}

/* .login-input-container:first-of-type {

} */

.login-input-container {
  margin: 20px auto;
  width: 280px;
  text-align: left;
}

.new-account-button {
  color: #00d30d;
  border-color: #00d30d;
}





@media only screen and (min-width: 500px) {

  .login-logo {
    width: 52vmin;
    height: 30vmin;
  }

  .login {
    max-width: 74%;
  }

}

@media only screen and (min-width: 600px) {

  .login-logo {
    width: 44vmin;
    height: 25vmin;
  }

  .login {
    max-width: 404px;
  }


}

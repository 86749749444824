*, *::before, *::after {
  box-sizing: border-box;
  font-display: swap;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 100vw;
  overflow-x: hidden;
  text-align: center;
  /* padding-bottom: 60px; */
}

p {
  letter-spacing: 0.01em;
  font-size: 18px;
  line-height: 1.40em;
  max-width: 80%;
  text-align: left;
  margin: 10px auto;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}



.App-link {
  color: #61dafb;
}

table {
  margin: 0 auto;
  border-collapse: collapse;
}

td {
  padding: 10px;
  border: 1px solid slategray;
}



.temporary-message {
  /* position: fixed; */
  background-color: #f3f3f3;
  /* width: 90%; */
  /* margin-left: 5%; */
  text-align: left;
  /* margin-top: 440px; */
  padding: 5px;
}

.temporary-close {
  display: none;
}

.message-close {
  display: inline-block;
  background-color: red;
  padding: 2px 6px;
  margin-left: 10%;
  font-size: 12px;
}

.message-text {
  font-size: 11px;  
}
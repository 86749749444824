.ApexSystem {
  padding: 40px 0;
  width: 100vw;
  min-height: 700px;
  background-color: #fff;
  background-image: url('../../images/2000-20LD-angle.jpg');
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: -140px 100px;
  /* background-size: 70%; */
  /* background-position: -150px -30px; */
}

.as-text-container {
  width: 90%;
  /* width: 500px; */
  display: inline-block;
  background-color: #1e1f2099;
  color: #f3f3f3;
  position: relative;
  /* top: 40px; */
  /* left: 300px; */
  border-radius: 10px;
}

.as-darker {
  border-radius: 10px;  
  padding: 20px;
}

.as-title {
  display: inline-block;
  width: 64%;
  color: #00d30d;
  font-weight: 500;
}

.as-text {
  display: inline-block;
  font-weight: 300;
  width: 358px;
}

@media only screen and (min-width: 440px) {
  .ApexSystem {
    background-size: 160%;
    background-position: -140px 60px;
    /* background-size: 70%; */
    /* background-position: -150px -30px; */
  }
}

@media only screen and (min-width: 600px) {
    .ApexSystem {
    background-size: 140%;
    background-position: -140px 20px;
    /* background-size: 70%; */
    /* background-position: -150px -30px; */
  }

  .as-text-container {
  width: 70%;
  /* width: 500px; */

  /* top: 40px; */
  /* left: 300px; */
}
}


@media only screen and (min-width: 800px) {

    .ApexSystem {

    background-size: 70%;
    background-position: -150px -30px;
  }
}

@media only screen and (min-width: 1000px) {

    .ApexSystem {

    background-size: 70%;
    background-position: -150px -30px;
  }

    .as-text-container {
  width: 500px;

  top: 40px;
  left: 300px;
}

}
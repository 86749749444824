.signup-container {
  min-height: 100vh;
  background-color: #1e1f20;
  padding-top: 20px;
  width: 100vw;
}

.signup-logo {
  background-image: url('../../images/EliteExtractionsWhiteGreen.png');
  width: 70vmin;
  height: 40vmin;
  background-size: contain;
  background-repeat: no-repeat;
  /* border: 2px solid magenta; */
  margin: 0 auto 20px;
}

.signup-input {
  padding: 8px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 10px;
  border: 2px solid #A9B2BC;
  width: 280px;
  background-color: #f3f3f3;
}

.signup-form {
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  border: 2px solid #1e1f20;
  max-width: 84%;
  border-radius: 10px;
  background-color: #f3f3f3;
}

.signup-label {
  margin-left: 8px;
  font-size: 14px;
}

.signup-form-input-container {
  margin: 8px auto;
  width: 280px;
  text-align: left;
}

.signup-page-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #00d30d;
  /* background-color: #062444; */
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 12px 14px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
  margin: 10px auto 10px auto;
  display: block;
  width: 250px;
}

.signup-page-button:disabled {
  pointer-events: none;
  color: #A9B2BC;
  border-color: #A9B2BC;
  background-color: #f3f3f3;
}

.signup-page-button:hover {
  background-color: #00d30d;
  color: #f3f3f3;
  border-color: transparent;
} 


@media only screen and (min-width: 500px) {

  /* .login-logo {
    width: 52vmin;
    height: 30vmin;
  } */

  .signup-form {
    max-width: 74%;
  }

}

@media only screen and (min-width: 600px) {

  /* .login-logo {
    width: 44vmin;
    height: 25vmin;
  } */

  .signup-form  {
    max-width: 404px;
  }


}

@media only screen and (min-width: 940px) {

  .signup-form {
  margin: 0 auto;
  padding: 16px 0;
  text-align: center;
  border: 2px solid #1e1f20;
  /* width: 84%; */
  border-radius: 10px;
  background-color: #f3f3f3;
  /* height: 80vh; */
  max-width: 60%;
  }

  .signup-logo {
  margin-top: 40px;
  width: 35vmin;
  height: 20vmin;
}

  .signup-input {
    margin: 0;
  }

  .signup-form-input-container {
    display: inline-block;
    width: 40%;
    margin: 10px 10px;
    /* border: 2px solid magenta; */
  }
}


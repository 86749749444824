.shopping-cart {
  background-color: #f3f3f3;
  margin-top: 40px;
}

.place-order-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #00d30d;
  /* background-color: #062444; */
  /* background-color: transparent; */
  border: 2px solid #A9B2BC;
  padding: 8px 10px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
  margin: 6px auto;
  background-color: #f3f3f3;
}

.place-order-button:hover {
  background-color: #00d30d;
  color: #f3f3f3;
  border-color: #f3f3f3;
}


.sc-quantity-container{
  height: 45px;
  width: 84px;
  display: inline-flex;
  flex-direction: row;
  padding: 0;
  /* border: 1px solid black; */
  /* border-collapse: collapse; */
}

.sc-quantity-display-increase,
.sc-quantity-display,
.sc-quantity-display-reduce {
  width: 34.33%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
}

.sc-quantity-display-increase:hover,
.sc-quantity-display-reduce:hover {
  cursor: pointer;
}

.sc-quantity-display-increase {
  background-color: lightgreen;
}

.sc-quantity-display-reduce {
  background-color: lightcoral;
}

.place-order-button {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid slategray;
  display: inline-block;
}
/* 
.blank {
  border: none; */
  /* background-color: none;
} */


.SuperCritical {
  /* background: linear-gradient(to right, #1e1f2090, #1e1f2099, #1e1f2099, #1e1f2099, #1e1f2090), url('../../images/mature-plants.jpg'); */
  /* background: url('../../images/mature-plants.jpg'); */
  background-color: #1e1f20;
  padding: 40px 0;
  background-size: cover;
}

.superCritical-darker {
  background-color: #1e1f2099;
  /* border: 2px solid magenta; */
  padding: 40px 0 20px 0;
  border-radius: 10px;
}

.superCritical-text-container {
  background-color: #1e1f2090;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
}

.superCritical-title {
  font-size: 20px;
}

.superCritical-subtitle {
  margin: 4px auto 10px auto;
}

.superCritical-text {
  color: #f3f3f3;
  max-width: 100%;
}

.superCritical-bold {
  font-weight: 700;
  color: #00d30d;
}

@media only screen and (min-width: 780px) {
  .SuperCritical {
    /* background: linear-gradient(to right, #1e1f2090, #1e1f2099, #1e1f2099, #1e1f2099, #1e1f2090), url('../../images/mature-plants.jpg'); */
    background: url('../../images/mature-plants.jpg');
    /* background-color: #1e1f20; */
    /* padding: 40px 0; */
    background-size: cover;
  }

  .superCritical-text {
  max-width: 90%;
}
}
.orders-table {
  margin-top: 60px;
  background-color: #f3f3f3;
}

.details-button-container {
  display: flex;
  padding: 0;
}

.order-details-button {
  background-color: lightgrey;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
}

.order-details-button:hover {
  background-color: grey;
  cursor: pointer;
}
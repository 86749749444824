.TabBar {
  position: fixed;
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  z-index: 10000000;
  border-top: 3px solid #A9B2BC;
  /* top: 20px; */
  width: 100vw;
  height: 36px;
  bottom: 0;
}

.tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  /* width: 100vw; */
  /* height: 30px; */
  color: #1e1f20;
  /* border: 2px solid magenta; */
  width: 100%;
  height: 36px;
}

.tab:hover {
  background-color: #dddbdb;
}

.tab:visited {
  color: #1e1f20;
}

.side-nav {
  position: absolute;
  /* z-index: 1; */
  width: 50vw;
  height: calc(100vh - 36px);
  background-color: #dddbdb;
  color: black;
  right: 0;
  bottom: 36px;
  /* top: 36px; */
  display: flex;
  flex-direction: column;
  transition: right .6s;
  padding: 10px 0 0 0;
}

.side-nav-link {
  color: black;
  padding: 4px;
  text-decoration: none;
  transition: background-color .8s;
}

.side-nav-link:hover {
  background-color: #b3b2b2;
}

.hide {
  right: -51vw;
}

@media only screen and (min-width: 780px) {
  .TabBar {
    top: 0;
    border-bottom: 3px solid #A9B2BC;
    border-top: none;
  }

  .side-nav {
  top: 36px;
  width: 25%;
}
}
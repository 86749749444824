.private-label-offer {
  background-image: url('../../images/PotleafFromPotleaves.svg');
  background-size: 8%;
  /* background-repeat: no-repeat; */
  /* background:  */
  width: 100vw;
  /* height: 100vh; */
  background-color: #1e1f20;
}

.pl-offer-content {
  padding: 40px 0 60px 0;
  margin: 0 auto;
  /* height: 100vh; */
  color: #f3f3f3fa;
  width: 90vw;
  background: linear-gradient(to right, #1e1f2099, #1e1f20, #1e1f20,  #1e1f20, #1e1f20, #1e1f20, #1e1f20, #1e1f2099);
  /* background-color: #00000090; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.pl-title {
  color: #00d30d;
}

.pl-offer-text {
  margin-bottom: 60px;
  font-weight: 400;
  max-width: 70%;
}

.private-label-li {
  max-width: 70%;
  font-weight: 400;
}

.private-label-li:last-of-type {
  margin-bottom: 60px;
}

.begining-bold {
  font-weight: 700;
  color: #00d30d;
}

.private-label-link {
  font-weight: 500;
  text-decoration: none;
  color:#00d30d;
  background-color: #1e1f20;
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 20px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
}

.private-label-link:hover {
  color: #1e1f20;
  background-color: #00d30d;
  border-color: transparent;
}

.green-break {
  background-color: #00d30d;
  width: 100vw;
  height: 20px;
}
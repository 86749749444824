footer {
  background-color: #4e5753;
  color: #A9B2BC;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  padding-bottom: 40px;
}

.footer-h4 {
  margin: 20px auto 20px auto;
}

.footer-location-link {
  display: block;
  color: #A9B2BC;
  /* text-decoration: none; */
  margin: 8px;
  font-size: 18px;
  transition: 1s color, 1s text-decoration-color;
  text-decoration-color: #535964;
}

.footer-location-link:hover {
  color: #00d30d;
  text-decoration-color: #00d30d;
}

.social-media-container {
  margin: 0 auto;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.footer-p {
  text-align: center;
  margin: 20px auto 10px auto;
}

.footer-link {
  display: block;
  width: 60%;
  margin: 20px auto;
    font-weight: 500;
  text-decoration: none;
  color: #A9B2BC;
  border: 2px solid #A9B2BC;
  padding: 20px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
}

.footer-link:hover {
  background-color: #00d30d;
  color: #636663;
  /* color: #062444; */
  border-color: #4e5753;
}

.twitter-logo {
  width: 64px;
  height: 64px;
  background-image: url('../../icons/twitter-3-64.png');
  transition: .75s background-image;
}

.twitter-logo:hover {
  background-image: url('../../icons/twitter-3-64-green.png');
}

.facebook-logo {
  width: 64px;
  height: 64px;
  background-image: url('../../icons/facebook-3-64.png');
  transition: .75s background-image;
}

.facebook-logo:hover {
  background-image: url('../../icons/facebook-3-64-green.png');
}

.instagram-logo {
  width: 64px;
  height: 64px;
  background-image: url('../../icons/instagram-64.png');
  transition: .75s background-image;
}

.instagram-logo:hover {
  background-image: url('../../icons/instagram-64-green.png');
}

.youtube-logo {
  width: 64px;
  height: 64px;
  background-image: url('../../icons/youtube-3-64.png');
  transition: .75s background-image;
}

.youtube-logo:hover {
  background-image: url('../../icons/youtube-3-64-green.png');
}














@media only screen and (min-width: 900px) {
    footer {
    flex-direction: row;
  }

  .footer-div {
  width: 33vw;
  }

}
.email-signup-section {
  padding: 20px 0 20px 0;
  color: #1e1f20;
  background-color: #00d30d;
}

.email-signup-section h3 {
  font-size: 2em;
}

.email-offer {
  font-size: 15px;
  max-width: 80%;
  margin: 6px auto;
}

.email-input {
  padding: 10px;
  font-size: 18px;
  margin: 15px auto 5px auto;
  border-radius: 10px;
  border-color: transparent;
  width: 280px;
  background-color: #f3f3f3fa;
}
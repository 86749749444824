input {
  display: block;
  margin: 10px auto;
}

.customers {
  background-color: #f3f3f3;
  max-width: 90%;
  margin: 0px auto 0 auto;
}

.customers-title {
  color: #f3f3f3;
  margin: 60px 0 10px 0;
}
.hero-container {
  /* background-color: #282c34;   */
  padding: 0px 0 10px 0;
  color: #f3f3f3fa;
  /* padding-bottom: 10px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background: linear-gradient(to right, #1e1f2090, #1e1f2099, #1e1f2099, #1e1f2099, #1e1f2090), url('../../images/mature-plants.jpg'); */
  background-color: #1e1f20;
  /* background-color: #1e1f20; */
}

.darker {
  background-color: #1e1f2099;
}


.hero-login-container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100vw;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  /* padding: 40px 10px 0 0; */
  /* margin-bottom: 20px; */
}

.hero-login-link {
  /* margin-right: -1px; */
  color: #f3f3f3;
  text-decoration: none;
  /* min-width: 100px; */
  border: 2px solid #f3f3f3;
  padding: 8px;
  border-radius: 10px;
  font-size: 12px;
  transition: .75s background-color, .75s color, .75s border-color;
  /* background-color: */
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
}

/* NEED TO REMOVE THIS */
.hero-login-link:disabled {
  color: gray;
}

/* ****************** */

.hero-login-link:hover {
  color: #1e1f20;
  background-color: #f3f3f3;
  border-color: transparent;
}
/* 
.hero-login-link:visited {
  color: #f3f3f3fa;
} */

/* .hero-brands-container {
  width: 80vw;
  background-color: magenta;
  text-align: center;
  margin: 0 auto;
} */

.header-tagline {
  max-width: 74%;
  margin: 0 auto;
}

.hero-find-products-button {
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color:#00d30d;
  background-color: #1e1f2090;
  /* background-color: transparent; */
  border: 2px solid #00d30d;
  padding: 20px;
  border-radius: 10px;
  transition: .75s background-color, .75s color, .75s border-color;
  margin: 0px auto 20px auto;
}

.hero-find-products-button:hover {
  color: #1e1f20;
  background-color: #00d30d;
  border-color: transparent;
}

.hero-vibe-logo {
  height: 120px;
  width: 110px;
  background-image: url('../../images/Vibe Logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto 0px auto;
}

.hero-elite-logo {
  height: 60px;
  width: 100px;
  background-image: url('../../images/EliteExtractionsWhite.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* margin: 0px auto 10px auto; */
}


.hero {
  height: 40vmin;
  width: 70vmin;
  background-image: url('../../images/EliteExtractionsHeader.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  /* border: 2px solid magenta; */
}

.brands-card {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  /* padding: 10px; */
  text-align: center;
  width: 110px;
  margin: 0 auto;
  /* border: 2px solid magenta; */
}

.brands-card-text {
  margin: 0 auto;
  /* width: 100%; */
}

@media only screen and (min-width: 980px) {
  .hero-login-container {
    top: 46px;

  }
}

/* @media only screen and (min-width: 1000px) {
  .hero {
    height: 66vmin;
  }
} */



.add-product-form {
  background-color: #f3f3f3;
  width: 50%;
  margin: 20px auto;
  padding: 20px;
  /* display: flex;
  flex-wrap: wrap; */
  text-align: center;
  border-radius: 10px;
}

.add-product-input-container {
  margin: 0 auto;
  width: 50%;
}


.ap-input {
  width: 300px;
  padding: 4px;
}

.ap-submit-button {
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  width: 20%;
  margin: 0 auto;
}

.ap-submit-button:hover {
  background-color: lightgray;
}


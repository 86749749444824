
.user-account-page {
  margin-top: 60px;
}

.user-account-title {
  color: #f3f3f3;
}

.user-account-edit-account-button {
  color: #f3f3f3;
}

.user-account-edit-account-button:visited {
  color: #f3f3f3;
}

.user-account-orders-table {
  background-color: #f3f3f3;
}

.account-order-button {
  background-color: lightgray;
  padding: 0;
}

.account-order-button:hover {
  background-color: lime;
  cursor: pointer;
}

/* .reorder-button {
  width: 100%\;
  height: 39px;
} */

.reorder-link {
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: lime; */
  width: 100%;
  height: 39px;
}

.reorder-link:visited {
  color: black;
}
.order-details {
  padding-top: 60px;
  /* border: 2px solid magenta; */
  min-height: 100vh;
  margin-top: 36px;
}

.order-customer-info {
  background-color: #f3f3f3;
  display: inline-block;
  /* position: absolute; */
  /* margin-left: 20px; */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.order-table {
  background-color: #f3f3f3;
}